<template>
  <div id="login">
    <a-layout class="layout-style">
      <a-layout-header style="background: #F8F9FB">
        <a-row justify="end" align="middle">
          <GlobalOutlined class="ic-global" />
          <language-selector />
        </a-row>
      </a-layout-header>
      <a-layout-content>
        <a-row align="middle">
          <img :src="themeData.darkLogoList" class="logo">
        </a-row>
        <a-row style="margin-top: 54px; height: 458px">
          <div class="login-swiper flex-align">
            <img :src="themeData.bgList">
          </div>
          <div class="card-login">
            <h1>{{ $t('pages_login_005') }}</h1>
            <div class="card-body">
              <a-form
                :model="loginState"
                :rules="loginRules"
                class="login-form ant-round-form"
                @finish="loginSuccess"
              >
                <a-form-item name="username">
                  <div style="position: relative">
                    <a-input
                      v-model:value="loginState.username"
                      style="position: relative"
                      :placeholder="$t('pages_login_002')"
                    />
                    <!-- <a-form-item-rest>
                      <a-select
                        v-model:value="loginState.areacode"
                        :bordered="false"
                        class="area-select"
                      >
                        <a-select-option value="+86">+86</a-select-option>
                        <a-select-option value="+87">+87</a-select-option>
                      </a-select>
                    </a-form-item-rest> -->
                  </div>
                </a-form-item>

                <a-form-item name="password">
                  <a-input-password
                    v-model:value="loginState.password"
                    :placeholder="$t('pages_login_003')"
                  />
                </a-form-item>

                <div v-if="resMsg" class="error-text">{{ resMsg }}</div>

                <a-form-item v-if="needsCode" name="code">
                  <div class="flex-align">
                    <a-input
                      v-model:value="loginState.code"
                      style="border-radius: 3px"
                      :placeholder="$t('pages_login_008')"
                    />
                    <img class="code-src" :src="codeSrc" @click="loadValidateCode">
                  </div>
                </a-form-item>

                <div class="login-form-forgot"><span @click="() => sendVisible = true">{{ $t('pages_login_004') }}</span></div>

                <a-form-item style="margin-top: 64px">
                  <a-button
                    style="width: 100%"
                    shape="round"
                    type="primary"
                    html-type="submit"
                    :loading="loading"
                  >{{ $t('pages_login_005') }}</a-button>
                </a-form-item>
                <!-- <a-form-item style="text-align: center; margin-top: -18px;  font-size: 12px">
                  <span style="color: #999">{{ $t('pages_login_006') }}</span>
                  <span class="main-text-color" style="cursor: pointer" @click="$router.push('/register')">{{ $t('pages_login_007') }}</span>
                </a-form-item> -->
              </a-form>
            </div>
          </div>
        </a-row>
      </a-layout-content>
    </a-layout>
    <div class="keep-record">
      <div v-if="themeData.address">{{ themeData.address }}</div>
      <a target="_blank" href="https://beian.miit.gov.cn">{{ themeData.copyRight }}</a>
    </div>
    <password-forget
      v-model:send-visible="sendVisible"
      v-model:succ-visible="succVisible"
    />
    <slide-verify
      v-model:visible="visible"
      @success="loginSuccess"
    />
  </div>
</template>
<script>
import LangSelector from '@/components/lang-sel'
import PasswordForget from './components/password-forget'
import SlideVerify from '@/components/slide-verify'
import { ref, reactive, inject, defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { _login } from '@/api'
import { getIdentyImg } from '@/util'
import i18n from '@/locale'
export default defineComponent({
  name: 'Login',
  components: {
    'password-forget': PasswordForget,
    'slide-verify': SlideVerify,
    'language-selector': LangSelector
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const resMsg = ref('')
    const needsCode = ref(false)
    const codeSrc = ref('')
    const sendVisible = ref(false)
    const succVisible = ref(false)
    const loading = ref(false)
    const visible = ref(false)

    const themeData = inject('$themeData')

    const loginState = reactive({
      areacode: '+86',
      username: '',
      password: '',
      code: ''
    })

    const loginRules = {
      username: [{ required: true, trigger: 'change', message: i18n.global.t('pages_login_002') }],
      password: [{ required: true, trigger: 'change', message: i18n.global.t('pages_login_003') }],
      code: [{ required: true, trigger: 'change', message: i18n.global.t('pages_login_008') }]
    }

    const loginSuccess = async (e) => {
      resMsg.value = ''
      loading.value = true
      visible.value = false
      const data = {
        userName: loginState.username,
        password: loginState.password,
        code: loginState.code
      }
      if (e.sliderFlag) data.sliderFlag = e.sliderFlag
      try {
        const res = await _login.login(data)
        if (res.data.succ) {
          succDeal(res.data.data)
        } else {
          loading.value = false
          resMsg.value = res.data.resMsg
          if (['010001', '010006'].includes(res.data.resCode)) {
            needsCode.value = true
            loadValidateCode()
          }
        }
      } catch (e) {
        console.log(e)
        loading.value = false
      }
    }

    const loadValidateCode = () => {
      loginState.code = ''
      codeSrc.value = getIdentyImg(loginState.username)
    }

    const succDeal = async (data) => {
      const { menuList, userInfo, status, authFlag, activityStatus, entrySliderFlag } = data
      if (entrySliderFlag) {
        visible.value = true
        loading.value = false
      } else if (authFlag) {
        userInfo.status = status
        store.commit('updateMenuList', menuList)
        store.commit('updateUserInfo', userInfo)
        if (userInfo.mobile) {
          localStorage.setItem('mobileStatus', true)
          router.push('/home/index')
        } else {
          router.push('/user/account_materials')
        }
        await store.dispatch('getLanguage')
        loading.value = false
      } else {
        if (activityStatus === '40') {
          router.push('/guide_success')
        }
      }
    }

    const init = () => {
      if (route.query.source) {
        _login.loginCheck()
          .then(res => {
            if (res.data.succ) succDeal(res.data.data)
          }).catch(err => console.log(err))
      }
    }

    init()

    return {
      themeData,
      resMsg,
      needsCode,
      codeSrc,
      loading,
      visible,
      sendVisible,
      succVisible,
      loginState,
      loginRules,
      loginSuccess,
      loadValidateCode
    }
  }
})
</script>
<style lang="less" scoped>
#login {
  .scrollbar-none;
  background: #F8F9FB;
  height: 100vh;
  overflow-y: scroll;
  .ic-global {
    color: @main-color;
  };
  .logo {
    width: 180px;
  };
  .login-swiper {
    flex: 1.5;
    height: 100%;
    background: #fff;
    justify-content: center;
    img {
      width: 484px;
      height: 440px;
    }
  }
  .card-login {
    flex: 1;
    text-align: left;
    margin-left: 49px;
    background: #fff;
    box-shadow: 0px 2px 9px 6px rgba(245, 245, 245, 0.5);
    border-radius: 8px;
    padding: 39px 63px 0 44px;
    position: relative;
    .card-body {
      margin: 30px 0 0 37px;
      .error-text {
        color: @error-color;
        margin: -18px 0 12px 0;
      }
      .login-form-forgot {
        font-size: 12px;
        font-weight: 500;
        color: @main-color;
        text-align: right;
        margin: -12px 0 24px 0;
        span {
          cursor: pointer;
        }
      }
      .code-src {
        margin: 0 18px 0 36px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
  .keep-record {
    text-align: center;
    color: #9c9d9c;
    padding-top: 128px;
    a {
      color: #9c9d9c;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
#login::-webkit-scrollbar {
  display: none;
}
</style>
