<template>
  <a-modal
    :visible="visible"
    :closable="false"
    :mask-closable="false"
    :width="484"
    :title="$t('pages_login_010')"
    :footer="null"
    @cancel="onCancel"
  >
    <div id="slideBox">
      <div class="alert-area">
        <a-alert
          v-if="result === 'true'"
          show-icon
          type="success"
          :message="$t('pages_login_012', [times])"
        />
        <a-alert
          v-if="result === 'false'"
          show-icon
          type="error"
          :message="$t('pages_login_013')"
        />
      </div>
      <slide-verify
        class="slide-area"
        :w="400"
        :h="200"
        :slider-text="$t('pages_login_011')"
        :accuracy="3"
        :imgs="[
          require('@/assets/slide-images/a.png'),
          require('@/assets/slide-images/b.png'),
          require('@/assets/slide-images/c.png'),
          require('@/assets/slide-images/d.png'),
          require('@/assets/slide-images/e.png'),
          require('@/assets/slide-images/f.png'),
          require('@/assets/slide-images/g.png'),
          require('@/assets/slide-images/h.png')
        ]"
        @success="onSuccess"
        @fail="onFail"
      />
    </div>
  </a-modal>

</template>

<script>
import { reactive, toRefs } from 'vue'
import SlideVerify from 'vue3-slide-verify'
import 'vue3-slide-verify/dist/style.css'

export default {
  components: {
    'slide-verify': SlideVerify
  },

  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },

  setup(props, ctx) {
    const state = reactive({
      result: '',
      times: ''
    })

    const onCancel = () => {
      ctx.emit('update:visible', false)
      state.result = ''
      state.times = ''
    }

    const onSuccess = (value) => {
      state.result = 'true'
      state.times = (value / 1000).toFixed(1)
      ctx.emit('success', { sliderFlag: true })
    }

    const onFail = () => {
      state.result = 'false'
    }

    return {
      ...toRefs(state),
      onCancel,
      onSuccess,
      onFail
    }
  }
}
</script>
<style lang="less">
#slideBox {
  .alert-area {
    display: flex;
    justify-content: center;
    .ant-alert {
      width: 400px;
      margin-bottom: 24px;
    }
  }
  .slide-area {
    margin: 0 auto 8px auto;
    .slide-verify-slider {
      border: 0;
      .slide-verify-slider-mask {
        background: #EAEFFB;
        .slide-verify-slider-mask-item {
          width: 45px;
          border-radius: 5px;
          box-shadow: 0 0 3px #ddd;
        }
        .slide-verify-slider-mask-item[data-v-f61c42f2]:hover {
          background: @main-color;
        }
        .slide-verify-slider-text {
          font-size: 16px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>
